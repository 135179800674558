import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../hooks/auth';
import { useTheme } from '../../../hooks/theme';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import ReactApexChart, { Props } from 'react-apexcharts';
import { FiInfo } from 'react-icons/fi';
import { Chats, Exam, Play, Popcorn } from 'phosphor-react';

import VideoTumbnail from '../../../components/VideoTumbnail';
import TooltipAdapter from '../../../components/TooltipAdapter';
import CardModule from '../../../components/CardModule';
import CardLevelProgress from '../../../components/CardLevelProgress';
import CardSimulatesProgress from '../../../components/CardSimulatesProgress';
import CardMainFeatures from '../../../components/CardMainFeatures';
import CardMovieHighlight from '../../../components/CardMovieHighlight';
import TodoListModule from '../../../components/TodoListModule';
import CardSeparator from '../../../components/CardSeparator';
import CardMaterialFeatrured from '../../../components/CardMaterialFeatrured';
import CardRanking from '../../../components/CardRanking';
import Samuquinha from '../../../components/Samuquinha';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';

import {
  Container,
  AvatarContainer,
  SectionContainer1,
  SectionContainer2,
  SectionContainer3,
  SectionContainer4,
  GraphicContainer,
  CardExamNotFound,
} from './styles';

import { API_S3 } from '../../../services/api_s3';

import { ILessonProps } from '../../../interfaces/Lesson';
import LoaderBIO from '../../../components/Loader';
import { IPraticeProps } from '../../../components/SimulatedModule';
import ModalHomeAlert from '../../../components/ModalHomeAlert';
import ShowByBreakpoint from '../../../utils/breakpointSize';
import ModalFirstAccess from '../../../components/ModalFirstAccess';
import CardBookHighlight from '../../../components/CardBookHighlight';
import BookCardCover from '../../../components/BookCardCover';

interface IStatisticsProps {
  totalLessonViews: number;
  totalExercises: number;
  totalTopicAnswers: number;
  totalMovies: number;
}

interface IGraphPraticeStatisticsProps {
  categories: string[];
  series: {
    name: string;
    data: number[];
  }[];
}

const Dashboard: React.FC = () => {
  const { theme } = useTheme();
  const { user } = useAuth();
  const history = useHistory();

  // Loading
  const [dashboardLoading, setDashboardLoading] = useState(true);

  // Data
  const [lastVideoData, setLastVideoData] = useState<ILessonProps>(
    {} as ILessonProps,
  );
  const [userStatistics, setUserStatistics] = useState<IStatisticsProps>(
    {} as IStatisticsProps,
  );
  const [praticesStatistics, setPraticesStatistics] =
    useState<IGraphPraticeStatisticsProps>({} as IGraphPraticeStatisticsProps);
  const [lastPraticeData, setLastPraticeData] = useState<
    IPraticeProps | undefined
  >(undefined);

  // Graphic options
  const lineChartOptionsDashboard: Props = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: 'dark',
      y: {
        formatter: function (value: number) {
          return value.toFixed(0) + '%';
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'name',
      /* categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ], */
      categories: praticesStatistics?.categories,
      labels: {
        style: {
          colors: theme === 'dark' ? '#dadbe790' : '#dadbe7',
          fontSize: '10px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme === 'dark' ? '#dadbe790' : '#dadbe7',
          fontSize: '10px',
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: theme === 'dark' ? '#dadbe750' : '#dadbe7',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors:
        theme === 'dark'
          ? ['#0a3f37', '#bcfed6'] // dark
          : ['#2bd7bd', '#296d62'], // light
    },
    colors:
      theme === 'dark'
        ? ['#0a3f37', '#bcfed6'] // dark
        : ['#2bd7bd', '#296d62'], // light
  };

  // Get last video viewed
  useEffect(() => {
    (async () => {
      try {
        setDashboardLoading(true);
        const [
          responseLastLessons,
          responseUserStatistics,
          responsePraticeStatistics,
        ] = await Promise.all([
          api.get('/clusters/lessons/last'),
          api.get('/statistics/profile-counters'),
          api.get('/practices/statistics'),
        ]);

        // Last Lesson
        console.log(responseLastLessons.data);
        setLastVideoData(responseLastLessons.data);

        // User Statistics
        console.log(responseUserStatistics.data);
        setUserStatistics(responseUserStatistics.data);

        // Practice Statistics
        console.log('Practice Statistics: ', responsePraticeStatistics.data);
        setPraticesStatistics(responsePraticeStatistics.data);

        const responseLastPratice = await api.get('/practices/last');

        // Last Pratice
        console.log(responseLastPratice.data);
        setLastPraticeData(responseLastPratice.data);
      } catch (error) {
        console.log(error);
        setDashboardLoading(false);
      } finally {
        setDashboardLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <ModalFirstAccess />
      <ModalHomeAlert />
      <LoaderBIO isVisible={dashboardLoading || !user.id} />

      <Container>
        <HeaderSectionContainer>
          <AvatarContainer>
            <aside>
              <Samuquinha />
              <header>
                <h1>Olá, {user.first_name}!</h1>
                <p>Você está na melhor plataforma de biologia do mundo!</p>
              </header>
            </aside>
          </AvatarContainer>
        </HeaderSectionContainer>
        <SectionContainer1>
          <CardMainFeatures
            icon={Play}
            title="Aulas assistidas"
            value={userStatistics?.totalLessonViews || 0}
            iconBackground={theme === 'dark' ? '#ffffff1f' : '#2cc292'}
          />
          <CardMainFeatures
            icon={Exam}
            title="Exercícios resolvidos"
            value={userStatistics?.totalExercises || 0}
            iconBackground={theme === 'dark' ? '#ffffff1f' : '#2cc292'}
          />
          <CardMainFeatures
            icon={Chats}
            title="Tópicos respondidos"
            value={userStatistics?.totalTopicAnswers || 0}
            iconBackground={theme === 'dark' ? '#ffffff1f' : '#2cc292'}
          />
          <CardMainFeatures
            icon={Popcorn}
            title="Filmes avaliados"
            value={userStatistics?.totalMovies || 0}
            iconBackground={theme === 'dark' ? '#ffffff1f' : '#2cc292'}
          />
        </SectionContainer1>

        <SectionContainer2>
          {lastVideoData && (
            <VideoTumbnail
              videoTitle={lastVideoData?.title}
              videoTumbnailUrl={
                lastVideoData?.video_provider === 'youtube'
                  ? `https://img.youtube.com/vi/${lastVideoData?.video}/maxresdefault.jpg`
                  : lastVideoData?.thumbnail_url
              }
              percentView={
                (Number(lastVideoData?.viewed) * 100) /
                Number(lastVideoData?.duration)
              }
              dateView={lastVideoData?.updated_at}
              onClick={() => {
                lastVideoData.is_study_plan
                  ? history.push(
                      `/plano-de-estudos/${user.study_plan?.slug}/${lastVideoData.cluster?.slug}/${lastVideoData.slug}`,
                    )
                  : history.push(
                      `/aulas/${lastVideoData.cluster?.slug}/${lastVideoData.slug}`,
                    );
              }}
            />
          )}
          <CardLevelProgress />

          <ShowByBreakpoint size={1025}>
            {lastPraticeData ? (
              <CardSimulatesProgress
                id={lastPraticeData?.id}
                totalQuestions={lastPraticeData?.total_questions}
                totalCorrects={lastPraticeData?.total_hits}
                link={`/simulado/${lastPraticeData.id}`}
                expires_at={lastPraticeData?.expires_at}
                created_at={lastPraticeData?.created_at}
                isAvailable={lastPraticeData?.is_available}
                isFinished={lastPraticeData?.is_finished}
                height="100%"
              />
            ) : (
              <CardExamNotFound to="/simulados/novo">
                <header>
                  <TooltipAdapter
                    alt="Gere novos simulados quando quiser. 😊"
                    place="top"
                    cursor="help"
                  >
                    <Exam size={64} color="#ffffff" weight="duotone" />
                  </TooltipAdapter>
                </header>
                <footer>
                  <h2>Você ainda não fez nenhum simulado</h2>
                  <p>Que tal começar agora mesmo? 😊</p>
                </footer>
                <main />
              </CardExamNotFound>
            )}
          </ShowByBreakpoint>
        </SectionContainer2>

        <SectionContainer3>
          <GraphicContainer>
            <header>
              <h2>Simulados</h2>
              <TooltipAdapter
                alt="As estatísticas dos simulados <br /> são  atualizadas diariamente."
                place="top"
                cursor="help"
              >
                <FiInfo strokeWidth={2.5} size={20} />
              </TooltipAdapter>
            </header>
            {praticesStatistics?.series && (
              <ReactApexChart
                options={lineChartOptionsDashboard}
                //series={praticesStatistics?.series}
                series={praticesStatistics?.series.map(serie => {
                  return {
                    name: serie.name,
                    data: serie.data.map(data => {
                      return Number((data * 100).toFixed(0));
                    }),
                  };
                })}
                type="area"
                width="100%"
                height="100%"
              />
            )}
          </GraphicContainer>

          {user.study_plan?.id ? (
            <CardModule
              id={user.study_plan?.id}
              title={user.study_plan?.title}
              description={user.study_plan?.description}
              link={`/plano-de-estudos/${user.study_plan?.slug}/!`}
              checked={true}
              background={user.study_plan?.thumbnail_url}
            />
          ) : (
            <CardModule
              id={'77'}
              title="Escolha um plano de estudos"
              description={
                'Escolha um plano de estudos para começar a estudar.'
              }
              link={`/plano-de-estudos/?change=true`}
              checked={false}
              linkTitle="Escolher plano"
              background={`${API_S3}/default/study-plan-bg-default.jpg`}
            />
          )}

          <CardMovieHighlight />
        </SectionContainer3>

        <SectionContainer4>
          {/* <CardBookHighlight /> */}
          <BookCardCover />

          <CardRanking />

          <ShowByBreakpoint size={1025}>
            <TodoListModule />
          </ShowByBreakpoint>
        </SectionContainer4>
      </Container>
    </>
  );
};

export default Dashboard;
