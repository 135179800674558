import React, { useCallback, useEffect, useState } from 'react';

import { useTheme } from '../../../hooks/theme';

import api from '../../../services/api';

//@ts-ignore
import ProgressBar from 'react-animated-progress-bar';

import { GraduationCap, House, MagnifyingGlass } from 'phosphor-react';

import LoaderBIO from '../../../components/Loader';
import Breadcrumbs from '../../../components/Breadcrumbs';
import InputDebounceAdapter from '../../../components/InputDebounceAdapter';
import HeaderSectionContainer from '../../../components/HeaderSectionContainer';
import NotFoundRegister from '../../../components/NotFoundRegister';


import {
  Container,
  HeaderSection,
  SectionContainer1,
  SearchContainer,
} from './styles';

import { IClusterProps } from '../../../interfaces/Cluster';
import { CardUniversity } from '../../admin/ModulosVestibulares/styles';

export interface IPaginationProps {
  page: number;
  maxPages: number;
  showingFrom: number;
  showingTo: number;
  totalRecords: number;
  records?: IClusterProps[];
}

const ModulosVestibulares: React.FC = () => {
  const { theme } = useTheme();
  const [loading, setLoading] = useState(false);

  const scrollRef = document.querySelector('#main-scroll');

  const [clustersData, setClustersData] = useState<IPaginationProps>(
    {} as IPaginationProps,
  );
  const [search, setSearch] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await api.get(`/clusters`, {
          params: {
            pageIndex: 0,
            pageSize: 99,
            search: search,
            type: 'exam',
          },
        });

        console.log(response.data);
        setClustersData(response.data);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [search]);

  const handleFilterModule = useCallback((value: string) => {
    console.log(value);
    setSearch(value);
  }, []);

  return (
    <Container>
      {loading && <LoaderBIO isVisible />}
      <HeaderSectionContainer padding="1rem 2rem">
        <h1>
          <GraduationCap weight="duotone" /> Vestibulares Específicos
        </h1>
        <p>Exercícios focados em vestibulares específicos.</p>
      </HeaderSectionContainer>

      <HeaderSection>
        <Breadcrumbs
          icon={House}
          separator="/"
          route={[{ title: 'Vestibulares', link: '/vestibulares' }]}
        />
        <SearchContainer>
          <InputDebounceAdapter
            type="text"
            debounceTimeout={300}
            minLength={3}
            onChange={event => handleFilterModule(event.target.value)}
            placeholder="Busque por vestibular..."
          />
          <MagnifyingGlass weight="duotone" />
        </SearchContainer>
      </HeaderSection>

      <SectionContainer1>
        {clustersData.records?.map(cluster => (
          <>
            <CardUniversity
              key={cluster.id}
              to={`aulas/${cluster.slug}/!`}
            >
              <div>
                <img src={cluster.thumbnail_url} alt={cluster.title} key={cluster.id} className={theme === 'dark' ? 'svg-light-mode' : 'svg-dark-mode'} />
              </div>
              <footer>
                <div>
                  <h5>{cluster.title}</h5>
                  <p>{cluster.progress.toFixed(0)}%</p>
                </div>
                <footer>
                  <ProgressBar
                    width="100%"
                    height="12px"
                    scrollArea={scrollRef}
                    percentage={cluster.progress.toFixed(0)}
                    rect                    
                    rectPadding="2px"
                    trackPathColor="#b7b7b730"
                    bgColor="#6befb0"
                    trackBorderColor="transparent"
                  />
                </footer>
              </footer>
            </CardUniversity>
          </>
        ))}
      </SectionContainer1>

      {clustersData.records?.length === 0 && <NotFoundRegister />}
    </Container>
  );
};

export default ModulosVestibulares;
