import React, { useEffect, useState } from 'react';

import api from '../../services/api';
import ReactApexChart, { Props } from 'react-apexcharts';
import { CaretDown } from 'phosphor-react';
import { useTheme } from '../../hooks/theme';

import { Container } from './styles';

interface ISalesDataProps {
  day: string;
  data: number[];
}

const CardSales: React.FC = () => {
  const { theme } = useTheme();
  
  const [salesData, setSalesData] = useState<ISalesDataProps>({} as ISalesDataProps);

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  console.log('Current Month: ', currentMonth);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/statistics/sales?month=${currentMonth}&year=${currentYear}`);
        setSalesData(response.data);
        console.log('Sales DATA: ', response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentMonth, currentYear]);

  // Graphic options
  const lineChartOptionsDashboard: Props = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: 'dark',
      y: {
        formatter: function (value: number) {
          return value.toFixed(0) + ' vendas';
        },
      },
      x: {
        formatter: function (value: string) {
          return `dia ${value}`;
        }
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'name',
      categories: salesData.data && salesData?.data.map((sale, index) => index + 1),
      labels: {
        style: {
          colors: theme === 'dark' ? '#dadbe790' : '#dadbe7',
          fontSize: '10px',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme === 'dark' ? '#dadbe790' : '#dadbe7',
          fontSize: '10px',
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: theme === 'dark' ? '#dadbe750' : '#dadbe7',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ['#2bd7bd'],
    },
    colors: ['#2bd7bd'],
  };

  return (
    <>
      <header>
        <h1>Gráfico de vendas
          ({salesData.data && salesData.data.reduce((acc, value) => acc + value, 0)} vendas)
        </h1>
        <aside>

          <div>
            <select name="mouth" defaultValue={currentMonth} onChange={(e) => setCurrentMonth(Number(e.target.value))}>
              <option value="1">Janeiro</option>
              <option value="2">Fevereiro</option>
              <option value="3">Março</option>
              <option value="4">Abril</option>
              <option value="5">Maio</option>
              <option value="6">Junho</option>
              <option value="7">Julho</option>
              <option value="8">Agosto</option>
              <option value="9">Setembro</option>
              <option value="10">Outubro</option>
              <option value="11">Novembro</option>
              <option value="12">Dezembro</option>
            </select>
            <CaretDown weight="duotone" />
          </div>
          <div>
            <select name="year" defaultValue={currentYear} onChange={(e) => setCurrentYear(Number(e.target.value))}>
              {/* <option value="2024">2024</option>
              <option value="2023">2023</option>
              <option value="2022">2022</option> */}
              {new Array(3).fill(0).map((_, index) => {
                let initialYear = 2022;
                const year = initialYear + index;
                return <option key={year} value={year}>{year}</option>
              })}
            </select>
            <CaretDown weight="duotone" />
          </div>
        </aside>
      </header>
       <ReactApexChart
        options={lineChartOptionsDashboard}
        series={[
          {
            name: 'Vendas do dia',
            data: salesData?.data,
          }
        ]}
        type="area"
        width="100%"
        height="100%"
      />
    </>
  );
}

export default CardSales;