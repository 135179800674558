import type React from 'react';

import { Container } from './styles';
import { useEffect, useState } from 'react';
import { IBooksProps } from '../../interfaces/Book';
import api from '../../services/api';
import { API_S3 } from '../../services/api_s3';

const BookCardCover: React.FC = () => {
  const [currentBook, setCurrentBook] = useState<IBooksProps>({} as IBooksProps);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/books/suggestions/last`);
        setCurrentBook(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Container to={`/sasabooks/${currentBook?.id}`} cover={`${API_S3}/${currentBook?.cover}`}>
      <div className="book-items">
        <div className="main-book-wrap">
          <div className="book-cover">
            <div className="book-inside"></div>
            <div className="book-image">
              <img src={`${API_S3}/${currentBook?.cover}`} alt='' />
              <div className="effect"></div>
              <div className="light"></div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default BookCardCover;