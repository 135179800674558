import styled from 'styled-components';

export const Container = styled.div`
`;

export const SectionContainer0 = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  padding: 1rem 0.5rem;
  gap: 1rem;
  position: relative;
  margin-bottom: 1rem;

  > aside {

    > a {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 0.5rem;
      text-transform: capitalize;
      font-size: 13px;
      padding: 13px;
      text-transform: capitalize;
      font-size: 12px;
      border-radius: 6px;
      background: #03bb85;
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
      background: ${props => props.theme.colors.primary};
    }
  }
`;

export const SectionContainer1 = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 0.68fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  
  > aside {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 1rem;
    gap: 1rem;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
    overflow: hidden;
    
    &:hover {
      
      &:after {
        content: 'Editar campanha';
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        background: rgba(0, 0, 0, 0.55);
        z-index: 999;
        cursor: pointer;
      }
    }
    
    > input {
      display: none;
    }
    
    > h1 {
      font-size: 14px;
      color: ${props => props.theme.colors.white};
    }
    
    > img {
      width: 100%;
      border-radius: 8px;
      
    }
  }
  
  > main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding: 1rem;
    gap: 1rem;
    background: ${props => props.theme.cards.primary};
    border: 1px solid ${props => props.theme.cards.border};
    box-shadow: ${props => props.theme.shadows.primary};
    border-radius: ${props => props.theme.radius.primary};
    overflow: hidden;
    
    &:hover {
      
      &:after {
        content: 'Editar campanha';
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        background: rgba(0, 0, 0, 0.55);
        z-index: 999;
        cursor: pointer;
      }
    }
    
    > input {
      display: none;
    }
    
    > h1 {
      font-size: 14px;
      color: ${props => props.theme.colors.white};
    }
    
    > img {
      width: 100%;
      border-radius: 8px;
    }
  }
`;

export const SectionContainer2 = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  padding: 1rem;
  border-radius: ${props => props.theme.radius.primary};
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  overflow: hidden;

  > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > aside {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 1rem;

      > div {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
  
        > select {
          padding: 0.5rem 2.5rem 0.5rem 0.7rem;
          background: ${props => props.theme.cards.primary};
          background: transparent;
          border: 1px solid ${props => props.theme.cards.border};
          box-shadow: ${props => props.theme.shadows.primary};
          border-radius: ${props => props.theme.radius.primary};
          color: ${props => props.theme.colors.white};
          width: 100%;
          height: 38px;
          font-size: 12px;
          z-index: 1;
      
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
      
          > option {
            color: ${props => props.theme.colors.white};
            background: ${props => props.theme.modal.background};
            z-index: 1;
          }
        }
    
        > svg {
          position: absolute;
          right: 1rem;
          z-index: 0;
        }
      }
    }
  }
`;

export const SectionContainerPlans = styled.section`
  position: relative;
  display: grid;
  width: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const SectionContainerConfirm = styled.section`
  position: relative;
  display: flex;
  width: 100%;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ComingSoon = styled.section`
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  gap: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0,0,0,0.55);
  top: 0;
  left: 0;
  color: #fff;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #00000080;
  }

  > p {
    font-size: 32px;
    font-weight: 600;
  }
`;

export const PlanCard = styled.div`
  border-radius: ${props => props.theme.radius.primary};
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  background: ${props => props.theme.cards.primary};
  border: 1px solid ${props => props.theme.cards.border};
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: ${props => props.theme.radius.primary};

  > header {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;

    > h1 {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 18px;
      color: ${props => props.theme.colors.white};
    }

    > div {

      > p:nth-child(1) {
        font-size: 14px;
      }

      > p:nth-child(2) {
        font-size: 20px;
      }

      > p:nth-child(3) {
        font-size: 14px;
      }
      }
  }
  
  > main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;

    > h1 {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 14px;
      color: ${props => props.theme.colors.white};
      gap: 0.5rem;
    }

    > footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;

      > aside {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > select {
          padding: 0.5rem 2.5rem 0.5rem 0.7rem;
          background: ${props => props.theme.cards.primary};
          background: transparent;
          border: 1px solid ${props => props.theme.cards.border};
          box-shadow: ${props => props.theme.shadows.primary};
          border-radius: ${props => props.theme.radius.primary};
          color: ${props => props.theme.colors.white};
          width: 100%;
          height: 48px;
          font-size: 12px;
          z-index: 1;
      
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
      
          > option {
            color: ${props => props.theme.colors.white};
            background: ${props => props.theme.modal.background};
            z-index: 1;
          }
        }
  
        > svg {
          position: absolute;
          right: 0.75rem;
          z-index: 0;
        }
      }

      > div {
        width: fit-content;

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          padding: 0 16px;
          color: ${props => props.theme.colors.white};
          background: transparent;
          border: 1px solid ${props => props.theme.cards.border};
  
          &:hover {
            background: ${props => props.theme.cards.border};
          }

          > svg {
            margin: 0;
          }
        }
      }

    }
  }
`;

export const ModalContainer = styled.section`
  position: relative;
  padding-bottom: 1.5rem;
  
  table {
    border: 1px solid ${props => props.theme.cards.border};
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }
  
  table tr {
    background-color: ${props => props.theme.modal.background};
    border: 1px solid #ddd;
    padding: .35em;
  }
  
  table th,
  table td {
    padding: .625em;
    text-align: center;
    
    &:last-child {
      justify-content: center;
      display: flex;
    }
  }
  
  table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }
  
  button {
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    background: #e53d3d;
    align-items: center;
    justify-content: center;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  `;

export const Line0 = styled.section`
  position: relative;
`;

export const ModalContainer2 = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1.5rem;
  
  > form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

  }
`;

export const Section1 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  gap: 1rem;
  `;

export const Section2 = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  gap: 1rem;
`;

export const FooterSection = styled.section`
  position: relative;
  width: 100%;

  > button {
    width: 100%;
  }
`;